<template>
  <div>


    <div class="card-toolbar mb-5 d-flex justify-content-between">
      <div>
        <!--            <router-link v-if="$can('customers.create')" to="/finances/customer-checking/create" class="btn btn-primary font-weight-bolder">-->
        <!--                <v-icon>mdi-plus</v-icon>-->
        <!--                {{ $t('customer-checking.add_customer-checking')}}-->
        <!--            </router-link>-->
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
          <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
        </button>
      </div>
      <div>
        <router-link v-if="$can('customer_checking.list')" to="/customers/customer-checking-history" class="btn btn-primary font-weight-bolder h-100">
          {{ $t('MENU.customer_checking_history') }}
        </router-link>
      </div>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="col-lg-6 mb-5">
              <label>{{ $t('customer_checking.customer') }}</label>
              <!-- <multiselect v-model="customer"
                           :placeholder="$t('customer_checking.customer')"
                           label="name"
                           track-by="id"
                           :options="customers"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect> -->
              <multiselect
                  id="customer_id"
                  v-model="customer"
                  :placeholder="$t('customer_checking.customer')"
                  label="fullname"
                  track-by="id"
                  :options="customers"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mb-5">
              <label for="from_date">{{ $t('customer_checking.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="to_date">{{ $t('customer_checking.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="last_validated_date">{{ $t('customer_checking.last_validated_date') }}</label>
              <input v-model="filters.last_validated_date" type="date" id="last_validated_date" class="form-control">
            </div>

            <div class="form-group col-md-6 mb-5">
              <label>{{ $t('customer_checking.currency') }}</label>
              <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select>
            </div>
            <div class="form-group col-md-6 mb-5">
              <label>{{ $t('customer_checking.validator_user') }}</label>
              <multiselect v-model="user"
                           :placeholder="$t('customer_checking.validator_user')"
                           label="name"
                           track-by="id"
                           :options="users"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @search-change="getUsers($event)">
              </multiselect>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="actual_amount" slot-scope="props">
            <span v-if="!props.row.last_validated_date">
                {{ props.row.actual_amount }}
            </span>
          </template>
          <template slot="actions" slot-scope="props">
            <template v-if="props.row.last_validated_date || !props.row.customer_validation_id">
              <v-btn icon color="pink" v-if="$can('customer_checking.update')" @click="goToEdit(props.row.customer_id,props.row.currency_id)">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('create')">mdi-file</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn icon color="pink" v-if="$can('customer_checking.update')" :to="'/customers/customer-checking/edit/'+props.row.customer_validation_id">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
              </v-btn>
            </template>

            <b-dropdown v-if="!props.row.is_confirmed && props.row.customer_validation_id && !props.row.last_validated_date" :id="'dropdown-offset_'+props.row.customer_validation_id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
              <a class="dropdown-item" v-if="$can('customer_checking.approve') && (!props.row.last_validated_date)" href="javascript:;" @click="makeApprove(props.row.customer_validation_id)">
                {{ $t('approve') }}
              </a>
            </b-dropdown>

            <b-dropdown v-if="props.row.is_confirmed && props.row.is_increase_decrease == '0' && props.row.actual_amount != props.row.last_value" :id="'dropdown-offset_'+props.row.customer_validation_id" :text="$t('more_actions') " variant="outline-primary" class="m-2">

              <a class="dropdown-item" v-if="$can('customer_checking.increase') && (props.row.last_validated_date && (props.row.actual_amount > props.row.last_value))" href="javascript:;" @click="actionIncreaseDecrease(props.row.customer_validation_id, 1, (props.row.actual_amount - props.row.last_value ))">
                {{ $t('increase') }}
              </a>
              <a class="dropdown-item" v-if="$can('customer_checking.decrease') && (props.row.last_validated_date && (props.row.actual_amount < props.row.last_value))" href="javascript:;" @click="actionIncreaseDecrease(props.row.customer_validation_id, 2, (props.row.actual_amount - props.row.last_value ))">
                {{ $t('decrease') }}
              </a>
            </b-dropdown>


          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="increase-decrease" size="md" hide-footer :title="$t(this.is_increase_decrease == 1?'increase':'decrease')">
      <div>
        <div class="form-group row">
          <div class="col-lg-12 mb-5">
            <label for="amount">{{ $t('amount') }}</label>
            <input name="amount" id="amount" type="number" min="0" v-model="amount" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''">
            <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.amount[0] }}
                        </span>
          </div>
        </div>
        <div class="card-footer pl-0 pr-0 pb-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="reset" class="btn btn-primary mr-2" @click="saveIncreaseDecrease">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary" @click="hideModal">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-customer-checking",
  data() {
    return {
      mainRoute: 'customers/customer-checking',
      subMainRoute: 'customers/customer-check',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        customer_id: this.$route.query.customer_id ? this.$route.query.customer_id : null,
        currency_id: null,
        from_date: null,
        to_date: null,
        last_validated_date: null,
        validator_user: null,
      },
      // status_list: [],
      columns: ['customer_name', 'credit', 'debit', 'currency_name', 'balance_last_validated_date',  'actual_amount', 'actions'],
      data: [],
      customer: null,
      user: null,
      customers: [],
      currencies: [],
      users: [],

      amount: null,
      is_increase_decrease: null, // 1 => increase, 2 => decrease
      customer_validated_id: null,
      validation: null,

    }
  },
  watch: {
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
    user: function (val) {
      if (val) {
        this.filters.validator_user = val.id;
      } else {
        this.filters.validator_user = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          customer_name: that.$t('customer_checking.customer'),
          credit: that.$t('customer_checking.credit'),
          debit: that.$t('customer_checking.debit'),
          currency_name: that.$t('customer_checking.currency_name'),
          balance_last_validated_date: that.$t('customer_checking.last_validate_date'),
          last_value: that.$t('customer_checking.last_val'),
          actual_amount: that.$t('customer_checking.actual_amount'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.customer_checking")}]);
    this.getCurrencies();
    // this.getCustomers();
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.currency_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.last_validated_date = null;
      this.filters.validator_user = null;
      this.user = null;
      this.customer = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    // getCustomers() {
    //   ApiService.get(this.mainRouteDependency + "/customers").then((response) => {
    //     this.customers = response.data.data;
    //     if (this.filters.customer_id) {
    //       this.customer = response.data.data.find((row) => row.id == this.filters.customer_id);
    //     }
    //   });
    // },
    getCustomers(filter) {
      if (filter && filter.length >= 3 && (filter.length != 0 || filter != null)) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    goToEdit(customer_id, currency_id) {
      this.$router.push({
        name: 'customer-checking.edit', query: {
          customer_id: customer_id,
          currency_id: currency_id,
        }
      });
    },
    makeApprove(customer_validation_id) {
      this.$confirmAlert(this.$t('do_you_want_make_it_approved'), this.actionApprove, customer_validation_id);
    },

    actionApprove(customer_validation_id) {
      ApiService.patch(`${this.subMainRoute}/approved/${customer_validation_id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    actionIncreaseDecrease(customer_validated_id, is_increase_decrease, amount) {
      this.amount = amount;
      this.customer_validated_id = customer_validated_id;
      this.is_increase_decrease = is_increase_decrease;
      this.showModal();
    },
    showModal() {
      this.$refs['increase-decrease'].show();
    },
    hideModal() {
      this.$refs['increase-decrease'].hide();
      this.amount = null;
      this.customer_validated_id = null;
      this.is_increase_decrease = null;
    },
    saveIncreaseDecrease() {
      ApiService.patch(`${this.subMainRoute}/update_amount/${this.customer_validated_id}`, {
        amount: this.amount,
        is_increase_decrease: this.is_increase_decrease,
      }).then((response) => {
        this.getFetch();
        this.hideModal();
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
  },
};
</script>
